<template>
  <item-group :item="item" sub-group :childs="childs" />
</template>

<script>
export default {
  name: 'ItemSubGroup',

  props: {
    item: {type: Object},
    childs: Array
  }
}
</script>
