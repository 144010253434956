export default [
  {
    icon: 'mdi-view-dashboard',
    title: 'dashboard',
    to: '/dashboard',
    open: false,
    permissions: 'Idn'
  },
  {
    icon: 'mdi-account',
    title: 'users',
    to: '/users/index',
    open: false,
    permissions: ['user-management.operation']
  },
  {
    icon: 'mdi-office-building',
    title: 'organizations',
    to: '/organizations/index',
    open: false,
    permissions: ['organization.operation']
  },
  {
    icon: 'mdi-file-tree',
    title: 'branches',
    to: '/branches/index',
    open: false,
    permissions: ['organization.operation']
  },
  {
    icon: 'mdi-account-hard-hat',
    title: 'workingSites',
    to: '/working-sites/index',
    open: false,
    permissions: ['working-site.operation']
  },
  {
    icon: 'mdi-hospital',
    title: 'health',
    to: '/health/index',
    open: false,
    permissions: ['medical.operation']
  },
  {
    icon: 'mdi-alert',
    title: 'alerts',
    to: '/alerts/index',
    open: false,
    permissions: ['alert.operation']
  },
  {
    icon: 'mdi-devices',
    title: 'devices',
    to: '/devices/index',
    open: false,
    permissions: ['organization.operation']
  },
  {
    icon: 'mdi-security',
    title: 'roles',
    to: '/roles/index',
    open: false,
    permissions: ['identity.roles']
  },
  {
    icon: 'mdi-email',
    title: 'tickets',
    to: '/tickets/index',
    open: false,
    permissions: ['message.operation']
  },
  {
    icon: 'mdi-account-multiple-plus',
    title: 'registerRequests',
    to: '/register-requests/index',
    open: false,
    permissions: ['user-register-request.operation']
  },

  ////////////////////////////////////////////
  ////////////// ORGANIZATION ///////////////
  ///////////////////////////////////////////

  {
    icon: 'mdi-account-hard-hat',
    title: 'workingSites',
    to: '/org/working-sites/index',
    open: false,
    permissions: ['org.working-site.operation']
  },
  {
    icon: 'mdi-account',
    title: 'users',
    to: '/org/users/index',
    open: false,
    permissions: ['org.user-management.operation']
  },
  {
    icon: 'mdi-security',
    title: 'roles',
    to: '/org/roles/index',
    open: false,
    permissions: ['org.identity.roles']
  },
  {
    icon: 'mdi-hospital',
    title: 'health',
    to: '/org/health/index',
    open: false,
    permissions: ['org.medical.operation']
  },
  {
    icon: 'mdi-alert',
    title: 'alerts',
    to: '/org/alerts/index',
    open: false,
    permissions: ['org.alert.operation']
  },
  {
    icon: 'mdi-devices',
    title: 'devices',
    to: '/org/devices/index',
    open: false,
    permissions: ['org.device.operation']
  },
  {
    icon: 'mdi-email',
    title: 'tickets',
    to: '/org/tickets/index',
    open: false,
    permissions: ['org.message.operation']
  },
  {
    icon: 'mdi-account-multiple-plus',
    title: 'registerRequests',
    to: '/org/register-requests/index',
    open: false,
    permissions: ['org.user-register-request.operation']
  }
]
