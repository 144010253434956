<template>
  <v-list-item :to="item.to" :class="itemClass" exact exact-active-class="primary">
    <v-list-item-icon v-if="item.icon">
      <v-icon v-text="item.icon" />
    </v-list-item-icon>

    <v-list-item-content v-if="item.title">
      <v-list-item-title> {{ $t(`nav.${item.title}`) }} </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Themeable from 'vuetify/lib/mixins/themeable'

export default {
  name: 'Item',
  mixins: [Themeable],
  props: {
    item: {type: Object, required: true},
    childs: Array
  },
  computed: {
    itemClass() {
      return [`${!this.isDark ? 'black' : 'white'}--text`]
    }
  },
  methods: {
    openItem() {
      this.item.open = true
    }
  }
}
</script>
