<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    dark
    mobile-breakpoint="960"
    app
    width="230"
    v-bind="$attrs"
  >
    <v-list-group v-if="currentOrg" color="white" class="my-2" sub-group>
      <template v-slot:activator>
        <v-list-item>
          <!-- <v-list-item-avatar class="align-self-center" contain color="white">
            <v-img src="@/static/img/branding/logo.svg" max-height="30" contain />
          </v-list-item-avatar> -->

          <v-list-item-content>
            <v-list-item-title class="text-truncate">
              {{ currentOrg.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list-item v-for="org in userOrganizations" :key="org.id" @click="changeOrg(org)">
        <v-list-item-content class="ps-3">
          <v-list-item-title> {{ org.id }} - {{ org.name }} </v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon v-if="org.id === currentOrg.id">
          <v-icon small>mdi-check</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list-group>

    <v-divider class="mb-2" />

    <template v-for="(item, i) in items">
      <template v-if="show(item)">
        <item-group v-if="item.children" :key="`group-${i}`" :item="item"> </item-group>

        <item v-else :key="`item-${i}`" :item="item" />
      </template>
    </template>
    <div />
  </v-navigation-drawer>
</template>

<script>
// Utilities
import {mapState, mapGetters} from 'vuex'
import {hasPermission} from '@/lib/utils'
import navItems from '../_nav'
import Item from './components/Item'
import ItemGroup from './components/ItemGroup'

export default {
  name: 'DashboardCoreDrawer',
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Item,
    ItemGroup
  },
  data() {
    return {
      childs: this.getChilds(navItems),
      items: navItems
    }
  },
  computed: {
    ...mapState(['barColor', 'barImage']),
    ...mapGetters(['userOrganizations', 'currentOrg']),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val)
      }
    }
  },
  methods: {
    changeOrg(newOrg) {
      if (this.$route.path !== '/dashboard') {
        this.$router.push('/dashboard')
      }
      this.$store.commit('SET_ORG_ID', newOrg.id)
    },
    traversItems(items, source) {
      items.forEach((item) => {
        if (item.children && item.children.length) {
          this.traversItems(item.children, source)
        } else {
          source.push(item)
        }
      })
    },
    getChilds(navItems) {
      const items = []
      this.traversItems(navItems, items)
      return items
    },
    show(item) {
      if (item.permissions === true || item.permissions === 'Idn') return true
      return item.permissions.some((permission) => hasPermission(permission))
    }
  }
}
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
