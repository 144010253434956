<template>
  <v-list-group
    :value="item.open"
    :prepend-icon="item.icon"
    :sub-group="subGroup"
    append-icon="mdi-menu-down"
    :color="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)' ? 'white' : 'grey darken-1'"
  >
    <template v-slot:activator>
      <v-list-item-content v-if="item.title">
        <v-list-item-title> {{ $t(`nav.${item.title}`) }} </v-list-item-title>
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in item.children">
      <template v-if="show(child)">
        <item-sub-group v-if="child.children" :key="`sub-group-${i}`" :item="child" :childs="childs" />

        <item v-else :key="`item-${i}`" :item="child" :childs="childs" />
      </template>
    </template>
  </v-list-group>
</template>

<script>
// Utilities
import {hasPermission} from '@/lib/utils'
import {mapState} from 'vuex'
import Item from './Item'
import ItemSubGroup from './ItemSubGroup'

export default {
  name: 'ItemGroup',
  inheritAttrs: false,
  components: {
    Item,
    ItemSubGroup
  },
  props: {
    item: {type: Object},
    subGroup: {type: Boolean, default: false},
    childs: Array
  },
  data() {
    return {
      open: null
    }
  },
  computed: {
    ...mapState(['barColor'])
  },

  methods: {
    show(item) {
      if (item.permissions === true) return true
      return item.permissions.some((permission) => hasPermission(permission))
    }
  }
}
</script>

<style>
.v-list-group__activator p {
  margin-bottom: 0;
}
</style>
